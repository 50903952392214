import React from 'react';

import {
    SEO,
    Main,
    Products,
    // Featured
} from '@components';

const IndexPage = () => (
    <>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Main />
        <Products />
        {/* <Featured /> */}
        {/* <Posts /> */}
    </>
);

export default IndexPage;
